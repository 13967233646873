import Vue from 'vue';
import { Button, Icon, Notify, RadioGroup, Radio, Toast } from 'vant';

Toast.setDefaultOptions({ position: 'top' });

Vue.use(Button);
Vue.use(Icon);
Vue.use(Notify);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Toast);

window.$notify = Notify;
window.$toast = Toast;
