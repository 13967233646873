import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { loadDevTool } from './utils/assist';

import './components';
import 'windi.css';
import './main.css';

Vue.config.productionTip = false;

function bootstrap() {
  const vm = new Vue({
    router,
    render: (h) => h(App),
  });

  vm.$mount('#app');

  if (process.env.VUE_APP_BUILD_MODE === 'testing') {
    loadDevTool();
  }
}

bootstrap();
